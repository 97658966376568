<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="m-modal" @click.stop>
        <div class="m-check mx-auto">
            <i class="fa-solid fa-bug check"></i>
        </div>
        <h6>Ooooooops!!!</h6>
        <p>Désolé, vos doits ne vous permettent pas de voir ou d'effectuer certaines actions</p>
        <button class="mx-auto valide" @click="$emit('close-modal')">D'accord, Merci</button>
      </div>
    </div>
</template>

<script>
    export default {
       name: 'AutaurizationModal'
    }
</script>

<style scoped>
    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        background-color: #000000be;
    }
    
    .m-modal {
        text-align: center;
        background-color: white;
        height: 450px;
        width: 500px;
        margin-top: 8%;
        padding: 45px 0;
        border-radius: 15px;
    }

    .m-check{
        width: 150px;
        height: 150px;
        background-color: #0000000e;
        border-radius: 100%;
    }
    
    .check {
        margin-top: 23%;
        font-size: 80px;
        color: #db3f18;
    }
    
    h6 {
        font-weight: 500;
        font-size: 28px;
        margin: 20px 0;
        color: #2b2a2a;
    }
    
    p {
        font-size: 16px;
        margin: 20px 10%;
        color: #4d4b4bcc;
    }
    
    .valide {
        background-color: #db3f18;
        width: 150px;
        height: 40px;
        color: white;
        font-size: 14px;
        border-radius: 6px;
        margin-top: 30px;
        outline: none;
        border: none;
    }

    .close {
        background-color: #ccc8c86b;
        width: 150px;
        height: 40px;
        color: gray;
        font-size: 14px;
        border-radius: 6px;
        margin-top: 50px;
        outline: none;
        border: none;
    }

    .valide:hover, .close:hover {
        transform: scale(0.95);
    }

  

    @media screen and (max-width: 414px) {
        .m-modal {
            width: 80%;
        }
    }


    @media screen and (max-width: 375px) {
        .m-modal {
            width: 80%;
        }
    }


    @media screen and (max-width: 280px) {
        .m-modal {
            width: 80%;
        }
    }
</style>