import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/authentication/LoginView.vue";

const routes = [
	//All authentication route
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/factor2auth",
		name: "Factor2Auth",
		component: () => import("../views/authentication/authenticationFactor.vue"),
	},
	{
		path: "/recovery-email",
		name: "Recovery Email",
		component: () => import("../views/authentication/RecoveryEmailView.vue"),
	},
	{
		path: "/recovery-code",
		name: "Recovery Code",
		component: () => import("../views/authentication/RecoveryCodeView.vue"),
	},
	{
		path: "/update-password",
		name: "Password Update",
		component: () => import("../views/authentication/UpdatePasswordView.vue"),
	},

	//Dashbord route
	{
		path: "/",
		name: "Dashboard",
		component: () => import("../views/DashboardView.vue"),
	},

	//Finance route
	{
		path: "/finance",
		name: "Finance",
		component: () => import("../views/FinanceStats.vue"),
	},

	{
		path: "/transferts",
		name: "Transfert",
		component: () => import("../views/Transferts/TransfertView.vue"),
	},

	{
		path: "/withdrawals",
		name: "Withdrawal",
		component: () => import("../views/withdrawal/WithdrawalView.vue"),
	},

	{
		path: "/treat-withdrawal",
		name: "Treat Withdrawal",
		component: () => import("../views/widgets/TreatWithdrawal.vue"),
	},

	{
		path: "/add-percentage",
		name: "Add Percentage",
		component: () => import("../views/widgets/AddTransfertPercentage.vue"),
	},

	//Setting route
	{
		path: "/setting",
		name: "Settings",
		component: () => import("../views/setting/SettingView.vue"),
	},

	//articles managment route
	{
		path: "/articles",
		name: "Articles",
		component: () => import("../views/articles/ArticlesView.vue"),
	},

	//Boostage managment route
	{
		path: "/boost",
		name: "Boostages",
		component: () => import("../views/boost/BoostView.vue"),
	},
	//Add boost plan
	{
		path: "/add-boost-plan",
		name: "Add Boost Plan",
		component: () => import("../views/widgets/AddBoostPlan.vue"),
	},
	//Update boost plan
	{
		path: "/update-boost-plan",
		name: "Update Boost Plan",
		component: () => import("../views/widgets/UpdateBoostPlan.vue"),
	},

	//referals rout
	{
		path: "/referals",
		name: "referals",
		component: () => import("../views/referals/ReferalView.vue"),
	},


	//categories rout
	{
		path: "/categories",
		name: "categories",
		component: () => import("../views/categories/CategoryView.vue"),
	},
	{
		path: "/add-category/:categoryId",
		name: "addcategories",
		component: () => import("../views/widgets/AddCategory.vue"),
	},

	//delivers rout
	{
		path: "/delivers",
		name: "Delivers",
		component: () => import("../views/delivers/DeliversView.vue"),
	},
	//Add deliver
	{
		path: "/add-deliver",
		name: "Add Deliver",
		component: () => import("../views/widgets/AddDeliver.vue"),
	},

	// Update Deliver
	{
		path: "/update-deliver",
		name: "Update Delivers",
		component: () => import("../views/delivers/UpdateDeliver.vue"),
	},

	//Get all delivery
	{
		path: "/delivery",
		name: "Get Delivery",
		component: () => import("../views/delivery/DeliveryView.vue"),
	},

	{
		path: "/assign-deliver",
		name: "Assign Deliver",
		component: () => import("../views/widgets/AssignDeliver.vue"),
	},

	//Rout for plan
	{
		path: "/add-delivery-plan",
		name: "Add Delivery Plan",
		component: () => import("../views/widgets/AddDeliveryPlan.vue"),
	},

	{
		path: "/update-delivery-plan",
		name: "Update Delivery Plan",
		component: () => import("../views/widgets/UpdateDeliveryPlan.vue"),
	},

	//Rout for pricing
	{
		path: "/add-delivery-pricing",
		name: "Add Delivery Pricing",
		component: () => import("../views/widgets/AddDeliveryPricing.vue"),
	},

	{
		path: "/update-delivery-pricing",
		name: "Update Delivery Pricing",
		component: () => import("../views/widgets/UpdateDeliveryPricing.vue"),
	},

	//Rout for pricing
	{
		path: "/pricing",
		name: "Pricing page",
		component: () => import("../views/pricing/PricingView.vue"),
	},

	{
		path: "/add-pricing",
		name: "Add Pricing",
		component: () => import("../views/widgets/AddGeneralPricing.vue"),
	},

	//Rout for pricing
	{
		path: "/store",
		name: "Store",
		component: () => import("../views/stores/StoresView.vue"),
	},

	//Rout of subscription
	{
		path: "/subscriptions",
		name: "Subscription page",
		component: () => import("../views/subscriptions/SubscriptionView.vue"),
	},
	{
		path: "/add-subscription-plan",
		name: "Add Subscription Plan",
		component: () => import("../views/widgets/AddSubscriptionPlan.vue"),
	},
	{
		path: "/update-subscription-plan",
		name: "Update Subscription Plan",
		component: () => import("../views/widgets/UpdateSubscriptionPlan.vue"),
	},

	//Rout videos
	{
		path: "/vlogs",
		name: "Videos",
		component: () => import("../views/videos/VideosView.vue"),
	},

	//Rout users
	{
		path: "/users",
		name: "Users",
		component: () => import("../views/users/UsersView.vue"),
	},
	{
		path: "/users/referals/:userId",
		name: "UserReferal",
		component: () => import("../views/users/UserReferals.vue"),
	},

	//Rout influencers
	{
		path: "/influencer",
		name: "Influencers",
		component: () => import("../views/influencers/InfluencersView.vue"),
	},

	{
		path: "/add-influencer",
		name: "Add Influencer",
		component: () => import("../views/widgets/AddInfluencer.vue"),
	},

	{
		path: "/add-influencer-code",
		name: "Add Influencer Code",
		component: () => import("../views/widgets/AddInfluencerCode.vue"),
	},

	//Rout for ads
	{
		path: "/ads",
		name: "Ads Carousel",
		component: () => import("../views/ads/AdsView.vue"),
	},

	{
		path: "/add-carousel",
		name: "Add Carousel",
		component: () => import("../views/widgets/AddCarousel.vue"),
	},

	{
		path: "/add-campaign",
		name: "Add Campaign",
		component: () => import("../views/widgets/AddNotification.vue"),
	},

	//Co-SuperAdmin routes
	{
		path: "/add-coadmin",
		name: "Co-Admin",
		component: () => import("../views/widgets/AddCoAdmin.vue"),
	},

	{
		path: "/update-coadmin",
		name: "Update Co-Admin",
		component: () => import("../views/widgets/UpdateCoAdmin.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
