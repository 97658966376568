import axios from "axios";

axios.defaults.baseURL = "https://api.afrilink.bj/api/v1";
axios.defaults.headers["Content-Type"] = "application/json";

export default class AuthService {
	//*************************************************************************************//
	//SuperAdmin login function
	//*************************************************************************************//
	adminLogin(adminInfos) {
		var data = JSON.stringify(adminInfos);

		var config = {
			method: "put",
			url: "/admin/auth/login",
			data: data,
		};
		return axios(config)
			.then((response) => response)
			.catch((error) => error);
	}

	//*************************************************************************************//
	//function for check if 2 authentication is activate
	//*************************************************************************************//
	f2AuthStatus(token) {
		var config = {
			method: "PUT",
			url: "/admin/profile/toggle-2fauth",
			headers: {
				Authorization: "Bearer " + token,
			},
		};
		return axios(config)
			.then((response) => response)
			.catch((error) => error);
	}

	//*************************************************************************************//
	//function for get SuperAdmin after login
	//*************************************************************************************//
	getAdminInfo(token) {
		var config = {
			method: "GET",
			url: "/admin/profile/get-data",
			headers: {
				Authorization: "Bearer " + token,
			},
		};
		return axios(config)
			.then((response) => response)
			.catch((error) => error);
	}

	//*************************************************************************************//
	//function for submit email if want recovery password
	//*************************************************************************************//
	recoveryEmail(recovEmail) {
		var data = JSON.stringify(recovEmail);
		var config = {
			method: "post",
			url: "/admin/auth/send-password-recovery-email",
			data: data,
		};
		return axios(config)
			.then((response) => response)
			.catch((error) => error);
	}

	//*************************************************************************************//
	//function for submit code if is 2 authentication
	//*************************************************************************************//
	login2Fauth(loginCode) {
		var config = {
			method: "PUT",
			url: "/admin/auth/login-2fauth?code=" + loginCode,
		};
		return axios(config)
			.then((response) => response)
			.catch((error) => {
				return error;
			});
	}

	//*************************************************************************************//
	//function for submit code if want recovery password
	//*************************************************************************************//
	recoveryCode(recovCode) {
		var config = {
			method: "POST",
			url: "/admin/auth/verify-recovery-code",
			data: {
				code: recovCode,
			},
		};

		return axios(config)
			.then((response) => response)
			.catch((error) => {
				return error;
			});
	}

	//*************************************************************************************//
	//function for update password
	//*************************************************************************************//
	updatePassword(passwordInfos) {
		var data = JSON.stringify(passwordInfos);
		var config = {
			method: "post",
			url: "/admin/auth/update-password-and-login",
			data: data,
		};
		return axios(config)
			.then((response) => response)
			.catch((error) => error);
	}

	// For get admin photo
	getAdminImage(imageId, token) {
		var config = {
			method: "GET",
			url: "/admin/file/" + imageId,
			headers: {
				Authorization: "Bearer " + token,
			},
		};

		return axios(config)
			.then((response) => response)
			.catch((error) => error);
	}
}
