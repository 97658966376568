<template>
    <div class="modal-overlay">
        <div class="m-modal">
          <img src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingModal"
    }
</script>

<style scoped>
    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        background-color: #000000be;
    }
    
    .m-modal {
        text-align: center;
        background-color: white;
        height: 50px;
        width: 50px;
        margin-top: 22%;
        padding: 9px;
        border-radius: 100%;
    }

    img{
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }

</style>