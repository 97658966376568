<template>
	<div class="body">
		<div class="banner">
			<img class="banner-img" src="../../assets/left-to-right.jpg" alt="" />
		</div>
		<div class="card">
			<div class="card__logo">
				<img
					class="card_img"
					src="../../assets/images/logo_noslg_nobg.png"
					alt=""
				/>
			</div>
			<h2 class="card__title">Bienvenue sur Afrilink Backoffice</h2>
			<h1 class="card__subtitle">Connectez-vous pour continuer</h1>
			<div class="form-row">
				<input
					v-model="email"
					class="form-row__input"
					type="email"
					placeholder="Adresse mail"
				/>
			</div>
			<div class="form-row">
				<input
					v-model="password"
					class="form-row__input"
					type="password"
					placeholder="Mot de passe"
				/>
			</div>
			<span>
				<router-link to="/recovery-email" class="float-end pass-forgot">
					Mot de passe oublié?
				</router-link>
			</span>
			<div class="form-row">
				<button
					@click="login()"
					class="button"
					:class="{ 'button--disabled': !validatedFields }"
				>
					<span v-if="status == 'loading'">Connexion en cours...</span>
					<span v-else>Connexion</span>
				</button>
			</div>
		</div>
	</div>

	<!-- Loading -->
	<LoadingModal v-show="loading" @close-modal="loading = false" />
	<AccessModal v-show="accessError" @close-modal="accessError = false" />

	<div v-if="!isError" class="alert-error">
		<span class="closebtn" onclick="this.parentElement.style.display='none';"
			>&times;</span
		>
		<strong><i class="fa-solid fa-circle-info"></i></strong> {{ errors }}
	</div>
	<div v-if="!isSuccess" class="alert-success">
		<span class="closebtn" onclick="this.parentElement.style.display='none';"
			>&times;</span
		>
		<strong><i class="fa-solid fa-octagon-check"></i></strong> {{ success }}
	</div>
</template>

<script>
import AuthService from "../../services/AuthServices.js";

import LoadingModal from "@/components/LoadingModal";
import AccessModal from "@/components/AutaurizationModal";

export default {
	name: "LoginView",
	components: { LoadingModal, AccessModal },

	data: function () {
		return {
			ready: false,
			status: "",
			email: "",
			password: "",
			rememberMe: false,
			authService: null,
			errors: "",
			success: "",

			accessError: false,
			show: true,

			loading: false,
		};
	},
	created: function () {
		this.authService = new AuthService();
	},

	mounted: function () {
		document.title = "Login.::.Afrilink";
		this.tokenIsValid(localStorage.getItem("token"));
	},
	computed: {
		validatedFields: function () {
			if (this.email != "" && this.password != "") {
				return true;
			} else {
				return false;
			}
		},

		isError: function () {
			if (this.errors == "") {
				return true;
			} else {
				return false;
			}
		},

		isSuccess: function () {
			if (this.success == "") {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		login: function () {
			const self = this;
			this.errors = "";
			this.success = "";
			this.loading = true;

			if (this.email != "" && this.password != "") {
				self.status = "loading";

				self.authService
					.adminLogin({
						login: self.email,
						password: self.password,
						rememberMe: self.rememberMe,
					})
					.then(function (responses) {
						self.status = "";
						self.loading = false;
						if (responses.status != 200) {
							if (responses.response.status === 401) {
								self.accessError = true;
								self.show = false;
								//
							} else {
								self.errors = responses.response.data.message;
							}
						} else {
							if (responses.data.need2FAuth == true) {
								self.$router.push("/factor2auth");
							} else {
								localStorage.setItem("token", responses.data.token);

								if (localStorage.getItem("token") != null) {
									self.getAdminInfos(responses.data.token);
								}

								self.$router.push("/");
							}
						}
					})
					.catch(function () {
						self.status = "";
						self.loading = false;
					});
			} else {
				self.loading = false;
				self.errors = "Veuillez renseigner les champs svp";
			}
		},

		getAdminInfos: function (token) {
			const self = this;
			this.errors = "";
			this.loading = true;

			this.authService
				.getAdminInfo(token)
				.then(function (responses) {
					self.status = "";
					self.loading = false;
					if (responses.status != 200) {
						if (responses.response.status === 401) {
							self.accessError = true;
							self.show = false;
						} else {
							self.errors = responses.response.data.message;
						}
					} else {
						localStorage.setItem("adm", JSON.stringify(responses.data.data));
						// localStorage.setItem("sa_lname", responses.data.data.lastname);
						// localStorage.setItem("sa_mail", responses.data.data.emailAddress);

						// localStorage.setItem("sa_id", responses.data.data.id);
						if (responses.data.data.photo.id != null) {
							self.getAdminPhoto(responses.data.data.photo.id, token);
						} else {
							self.$router.push("/");
						}
					}
				})
				.catch(function () {
					self.status = "";
					self.loading = false;
				});
		},

		getAdminPhoto: function (id, token) {
			const self = this;
			this.loading = true;

			this.authService
				.getAdminImage(id, token)
				.then(function (responses) {
					self.status = "";
					self.loading = false;
					if (responses.status != 200) {
						if (responses.response.status === 401) {
							self.accessError = true;
							self.show = false;
						} else {
							self.errors = responses.response.data.message;
						}
					} else {
						localStorage.setItem("sa_img", responses.data.data.url);

						self.$router.push("/");
					}
				})
				.catch(function (error) {
					self.status = "";
					self.loading = false;
					self.errors = error;
				});
		},

		tokenIsValid: function (token) {
			if (token != "" && token != null) {
				this.$router.push("/");
			}
		},
	},
};
</script>

<style scoped>
.body {
	background-image: linear-gradient(62deg, #162031 0%, #eaf3ff 100%);
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	min-height: 88vh;
	padding: 32px;
}

.banner {
	position: absolute;
	widows: 100%;
	top: 0;
	left: 0;
	right: 0;
}

.banner-img {
	width: 100%;
	height: 100vh;
}

.card {
	max-width: 100%;
	z-index: 999;
	width: 560px;
	background: white;
	border-radius: 13px;
	padding: 32px;
}

.card__logo {
	margin: 20px auto;
	width: 150px;
	height: 90px;
	/*background: #EAF3FF;*/
}

.card__title {
	text-align: center;
	font-weight: 700;
	font-size: 25px;
}
.card__subtitle {
	text-align: center;
	color: #666;
	font-size: 20px;
	font-weight: 300;
}

.form-row {
	display: flex;
	width: 100%;
	margin: 12px 0px;
	gap: 16px;
	flex-wrap: wrap;
}

.form-row__input {
	padding: 8px;
	border: none;
	border-radius: 8px;
	background: #f2f2f2;
	font-weight: 500;
	font-size: 16px;
	flex: 1;
	height: 50px;
	outline: none;
	min-width: 100px;
	color: black;
}

.form-row__input::placeholder {
	color: #aaaaaa;
}

.card_img {
	max-width: 100%;
	height: 50px;
	border-radius: 8px;
}

.button {
	background: #2e3190;
	color: white;
	border-radius: 8px;
	font-weight: 800;
	font-size: 15px;
	border: none;
	width: 100%;
	padding: 16px;
	transition: 0.4s background-color;
}
.card__action {
	color: #2e3190;
	text-decoration: underline;
}
.card__action:hover {
	cursor: pointer;
}
.button:hover {
	cursor: pointer;
	background: #1976d2;
}
.button--disabled {
	background: #1976d2;
	color: #ececec;
}
.button--disabled:hover {
	cursor: not-allowed;
	background: #155aa0;
}

.pass-forgot {
	color: #1976d2;
	margin-bottom: 1.2em;
	text-decoration: none;
	cursor: pointer;
}

.alert-error {
	padding: 20px;
	position: fixed;
	top: 10px;
	z-index: 999;
	border-radius: 5px;
	right: 10px;
	background-color: #f44336;
	border-left: 10px solid #8f241d;
	color: white;
}

.alert-success {
	padding: 20px;
	position: fixed;
	top: 10px;
	z-index: 999;
	border-radius: 5px;
	right: 10px;
	border-left: 10px solid #13d13c;
	background-color: #4aad57;
	color: white;
}

.closebtn {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
}

.closebtn:hover {
	color: rgb(114, 105, 105);
}
</style>
>
