<template>
  <router-view />
</template>

<style>
  body{
     background-color: #EAF3FF;
  }

  .alert-error {
        padding: 20px;
        position: fixed;
        top: 10px;
        z-index: 999;
        border-radius: 5px;
        right:10px;
        background-color: #f44336;
        border-left: 10px solid #8f241d;
        color: white;
    }

    .alert-success {
        padding: 20px;
        position: fixed;
        top: 10px;
        z-index: 999;
        border-radius: 5px;
        right:10px;
        border-left: 10px solid #13d13c;
        background-color: #0004d3;
        color: white;
    }

    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .closebtn:hover {
        color: rgb(114, 105, 105);
    }

</style>
