import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import CKEditor from '@ckeditor/ckeditor5-vue';
import Vue3Progress from "vue3-progress";
import vueCountryRegionSelect from "vue3-country-region-select";

import "@fortawesome/fontawesome-free/js/all";
import "bootstrap/dist/css/bootstrap.css";

import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "jszip";
import "pdfmake";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import _ from "lodash";

const options = {
	position: "fixed",
	height: "2px",
	color: "blue",
};

const app = createApp(App);

app.use(router);
app.use(Vue3Progress, options);
app.use(vueCountryRegionSelect);
app.use(VueViewer);
app.use(_);

app.mount("#app");
//app.use( CKEditor )
